import { axiosInstance } from "src/config/axiosInstance";

class ourhotelsService {
    static async fetchOurhotelsSummary(
        companyId,
        page = 1,
        numberOfItems = 20,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `${process.env.REACT_APP_HOST_API}/ourhotels/${companyId}?page=${page}&numberOfItems=${numberOfItems}`,
                );
                console.log(response);
                return response;
            } catch (error) {
                console.error("Error fetching ourhotels summary:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
}

export default ourhotelsService;
