import { axiosInstance } from "src/config/axiosInstance";

class HotelsServices {
    static #specialKey = process.env.REACT_APP_TZ_API_KEY;

    static async fetchHotelOffers(
        searchCriteria,
        maxRetries = 2,
        retryDelay = 1000,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/hotels/offers`,
                    searchCriteria,
                    { headers: { "tz-api-key": this.#specialKey } },
                );
                return response.data;
            } catch (error) {
                console.error(
                    `Attempt ${
                        retries + 1
                    } failed. Retrying in ${retryDelay} ms...`,
                );
                retries++;
                retryDelay *= 2; // Exponential backoff
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async fetchHotelDetails(
        GDSprovider,
        hotelId,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/hotels/hotelDetails/${GDSprovider}/${hotelId}`,
                    { headers: { "tz-api-key": this.#specialKey } },
                );
                return response.data;
            } catch (error) {
                console.error("Error fetching hotel details:", error.message);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchHotelOffer(GDSprovider, hotelId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/hotels/offers/${GDSprovider}/${hotelId}`,
                    { headers: { "tz-api-key": this.#specialKey } },
                );
                return response.data;
            } catch (error) {
                console.error(
                    "Error fetching hotel offer details:",
                    error.message,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createHotelBookingIntent(
        gdsprovider,
        bookingData,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/hotels/${gdsprovider}/booking/intent`,
                    bookingData,
                    { headers: { "Content-Type": "application/json" } },
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while booking hotel:",
                    error.message,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async bookHotel(bookingData, maxRetries, retryDelay) {
        const response = await axiosInstance.post(`/hotels/book`, bookingData, {
            headers: { "Content-Type": "application/json" },
        });

        return response.data;
    }

    static async fetchHotelBooking(
        GDSprovider,
        bookingId,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/hotels/bookings/${GDSprovider}/${bookingId}`,
                    { headers: { "tz-api-key": this.#specialKey } },
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching hotel booking:",
                    error.message,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchAllHotelBookings(maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/hotels/allBookings`,
                    { headers: { "tz-api-key": this.#specialKey } },
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching hotel booking:",
                    error.message,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async getHotelBookings(bookingData, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(`/hotels/bookings`, {
                    gdsprovider: bookingData.gdsprovider,
                    body: {
                        from: 1,
                        to: 100,
                        start: bookingData.serviceObject.checkIn,
                        end: bookingData.serviceObject.checkIn,
                    },
                });
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while fetching hotel booking:",
                    error.message,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async cancelHotel(bookingId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.delete(
                    `/hotels/cancelBooking/${bookingId}`,
                    { headers: { "tz-api-key": this.#specialKey } },
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while canceling hotel:",
                    error.message,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createHotelProposal(
        proposalData,
        maxRetries = 3,
        retryDelay = 2000,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    "/hotels/createHotelProposal",
                    proposalData,
                    { headers: { "tz-api-key": this.#specialKey } },
                );
                return response;
            } catch (error) {
                console.error(
                    "An error occurred while creating hotel proposal:",
                    error.message,
                );
                retries++;
                if (retries === maxRetries) {
                    throw error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async selectHotelProposal(
        hotelData,
        maxRetries = 3,
        retryDelay = 2000,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    "/hotels/selectHotelProposal",
                    hotelData,
                    { headers: { "tz-api-key": this.#specialKey } },
                );
                return response;
            } catch (error) {
                console.error(
                    "An error occurred while selecting hotel proposal:",
                    error.message,
                );
                retries++;
                if (retries === maxRetries) {
                    throw error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async proposeHotelOfProposal(
        hotelData,
        maxRetries = 3,
        retryDelay = 2000,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    "/hotels/proposeHotelOfProposal",
                    hotelData,
                    { headers: { "tz-api-key": this.#specialKey } },
                );
                return response;
            } catch (error) {
                console.error(
                    "An error occurred while selecting hotel proposal:",
                    error.message,
                );
                retries++;
                if (retries === maxRetries) {
                    throw error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async getHotelConfirmationDetails(
        bookingId,
        maxRetries = 3,
        retryDelay = 2000,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/hotels/bookingDetails/${bookingId}`,
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while getting hotel confirmation details:",
                    error.message,
                );
                retries++;
                if (retries === maxRetries) {
                    throw error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async updateManualHotel(id, data) {
        try {
            const response = await axiosInstance.put(
                `/hotels/update/manual/${id}`,
                data,
            );

            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while updating manual hotel:",
                error?.message,
            );
        }
    }

    static async getManualHotels(companyId, lang = "en") {
        try {
            const response = await axiosInstance.get(
                `/hotels/manuals/${companyId}?lang=${lang}`,
            );

            return response.data.data;
        } catch (error) {
            console.error(
                "An error occurred while getting manual hotels:",
                error?.message,
            );
            throw error;
        }
    }
}

export default HotelsServices;
