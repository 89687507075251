export const MAX_HOTEL_ROOMS = 4;
export const MAX_HOTEL_ADULTS = 6;
export const MAX_HOTEL_KIDS = 4;

export const DEFAULT_ROOM_SEARCH = [
    {
        adults: 2,
        kids: 0,
        /**
         * @type {number[]}
         */
        childrenAges: [],
    },
];

/**
 * @typedef {Object} Guest
 * @property {"Mr" | "Ms" | "Mrs"} title
 * @property {string} firstName
 * @property {string} lastName
 * @property {"Adult" | "Child"} type
 */
/**
 * @typedef {Object} RoomQuery
 * @property {string} adults
 * @property {string} kids
 * @property {string[]} childrenAges
 * @property {Guest[]} guests
 */

/**
 * @type {RoomQuery[]}
 */
export const DEFAULT_ROOMS_QUERY = [
    {
        adults: "2",
        kids: "0",
        childrenAges: [],
        guests: [],
    },
];

export const BOARD_OPTIONS = [
    // "AB",
    "AI",
    // "AS",
    // "B1",
    // "B2",
    "BB",
    // "CB",
    // "CE",
    // "CO",
    // "DB",
    // "DD",
    "FB",
    // "FE",
    // "FL",
    // "FR",
    // "FS",
    // "GB",
    "HB",
    // "HL",
    // "HR",
    // "HS",
    // "IB",
    // "LB",
    // "MB",
    // "PB",
    // "QB",
    // "QM",
    // "QS",
    // "RO",
    // "SB",
    "SC",
    "ALL",
    // "TL",
];
export const BOARDS = {
    AB: "AMERICAN BREAKFAST",
    AI: "ALL INCLUSIVE",
    AS: "All Inclusive Premium",
    B1: "Breakfast for one guest",
    B2: "Breakfast for two guests",
    BB: "BED AND BREAKFAST",
    CB: "CONTINENTAL BREAKFAST",
    CE: "DINNER INCLUDED",
    CO: "LUNCH INCLUDED",
    DB: "BUFFET BREAKFAST",
    DD: "DISNEY DINING",
    FB: "FULL BOARD",
    FE: "DISNEY FULL BOARD EXTRA PLUS",
    FL: "DISNEY FULL BOARD PLUS",
    FR: "DISNEY FULL BOARD PREMIUM",
    FS: "DISNEY FULL BOARD STANDARD",
    GB: "ENGLISH BREAKFAST",
    HB: "HALF BOARD",
    HL: "DISNEY HALF BOARD PLUS",
    HR: "DISNEY HALF BOARD PREMIUM",
    HS: "DISNEY HALF BOARD STANDARD",
    IB: "IRISH BREAKFAST",
    LB: "LIGHT BREAKFAST",
    MB: "HALF BOARD WITH BEVERAGES INCLUDED",
    PB: "FULL BOARD BEVERAGES INCLUDED",
    QB: "DISNEY QUICK SERVICE BREAKFAST",
    QM: "DISNEY QUICK SERVICE MEAL",
    QS: "DISNEY QUICK SERVICE",
    RO: "ROOM ONLY",
    SB: "SCOTTISH BREAKFAST",
    SC: "SELF CATERING",
    TL: "ALL INCLUSIVE SOFT",
    ALL:"ALL TYPE"
};

export const BOARDS_INFO = [
    {
        key: "AI",
        title: "ALL INCLUSIVE",
        description:
            "Includes lodging, all meals, beverages, and a wide variety of activities and services.",
    },
    {
        key: "BB",
        title: "BED AND BREAKFAST",
        description: "Includes lodging and breakfast.",
    },
    {
        key: "FB",
        title: "FULL BOARD",
        description: "Includes lodging, breakfast, lunch and dinner.",
    },
    {
        key: "HB",
        title: "HALF BOARD",
        description: "Includes lodging, breakfast and dinner.",
    },
    {
        key: "SC",
        title: "SELF CATERING",
        description:
            "Includes accommodation with kitchen equipped to prepare your own meals.",
    },
];
