import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import { useBoolean } from "src/hooks";
import { useLocales } from "src/locales";
import { GlobalDialogContext } from "./context";
import Button from "../ui/button";

export function GlobalDialogContextProvider({ children }) {
    const [dialogData, setDialogData] = useState(null);
    const { t } = useLocales();
    const isMounted = useRef(false);
    const dialogOpen = useBoolean();

    const triggerDialog = useCallback((data) => {
        setDialogData(data);
        dialogOpen.onTrue();
    }, []);
    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            return;
        }
        if (!dialogOpen.value) {
            setTimeout(() => {
                setDialogData(null);
            }, 500);
        }
    }, [dialogOpen.value, setDialogData]);
    return (
        <GlobalDialogContext.Provider
            value={{ triggerDialog, closeDialog: dialogOpen.onFalse }}
        >
            {children}
            <Dialog open={dialogOpen.value} onClose={dialogOpen.onFalse}>
                <DialogTitle textAlign="center">
                    {t(dialogData?.title)}
                </DialogTitle>
                <DialogContent>
                    <Typography textAlign="center" sx={{ marginBottom: 2 }}>
                        {dialogData?.content?.type === "html" ? (
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t(dialogData?.content?.text),
                                }}
                            ></span>
                        ) : (
                            dialogData?.content?.text
                        )}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ gap: 2 }}>
                    <Button
                        type="button"
                        size="md"
                        onClick={dialogOpen.onFalse}
                    >
                        {t("Close")}
                    </Button>
                    {dialogData?.action?.type === "redirect" && (
                        <Link to={dialogData?.action.href}>
                            <Button
                                type="button"
                                size="md"
                                onClick={dialogOpen.onFalse}
                            >
                                {t(dialogData.action.text)}
                            </Button>
                        </Link>
                    )}
                </DialogActions>
            </Dialog>
        </GlobalDialogContext.Provider>
    );
}
