import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";
//
import App from "./App";

// ----------------------------------------------------------------------
serviceWorkerRegistration.register();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <HelmetProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </HelmetProvider>,
);
