export const countries = [
    { code: "", label: "", phone: "", language: "" },
    { code: "AD", label: "Andorra", phone: "376", language: "Catalan" },
    {
        code: "AE",
        label: "United Arab Emirates",
        phone: "971",
        language: "Arabic",
    },
    { code: "AF", label: "Afghanistan", phone: "93", language: "Persian" },
    {
        code: "AG",
        label: "Antigua and Barbuda",
        phone: "1-268",
        language: "English",
    },
    { code: "AI", label: "Anguilla", phone: "1-264", language: "English" },
    { code: "AL", label: "Albania", phone: "355", language: "Albanian" },
    { code: "AM", label: "Armenia", phone: "374", language: "Armenian" },
    { code: "AO", label: "Angola", phone: "244", language: "Portuguese" },
    { code: "AQ", label: "Antarctica", phone: "672", language: "English" },
    { code: "AR", label: "Argentina", phone: "54", language: "Spanish" },
    {
        code: "AS",
        label: "American Samoa",
        phone: "1-684",
        language: "English",
    },
    { code: "AT", label: "Austria", phone: "43", language: "German" },
    {
        code: "AU",
        label: "Australia",
        phone: "61",
        suggested: true,
        language: "English",
    },
    { code: "AW", label: "Aruba", phone: "297", language: "Dutch" },
    { code: "AX", label: "Åland Islands", phone: "358", language: "Swedish" },
    { code: "AZ", label: "Azerbaijan", phone: "994", language: "Azerbaijani" },
    {
        code: "BA",
        label: "Bosnia and Herzegovina",
        phone: "387",
        language: "Bosnian",
    },
    { code: "BB", label: "Barbados", phone: "1-246", language: "English" },
    { code: "BD", label: "Bangladesh", phone: "880", language: "Bengali" },
    { code: "BE", label: "Belgium", phone: "32", language: "Dutch" },
    { code: "BF", label: "Burkina Faso", phone: "226", language: "French" },
    { code: "BG", label: "Bulgaria", phone: "359", language: "Bulgarian" },
    { code: "BH", label: "Bahrain", phone: "973", language: "Arabic" },
    { code: "BI", label: "Burundi", phone: "257", language: "Kirundi" },
    { code: "BJ", label: "Benin", phone: "229", language: "French" },
    { code: "BL", label: "Saint Barthélemy", phone: "590", language: "French" },
    { code: "BM", label: "Bermuda", phone: "1-441", language: "English" },
    { code: "BN", label: "Brunei Darussalam", phone: "673", language: "Malay" },
    { code: "BO", label: "Bolivia", phone: "591", language: "Spanish" },
    { code: "BR", label: "Brazil", phone: "55", language: "Portuguese" },
    { code: "BS", label: "Bahamas", phone: "1-242", language: "English" },
    { code: "BT", label: "Bhutan", phone: "975", language: "Dzongkha" },
    { code: "BV", label: "Bouvet Island", phone: "47", language: "Norwegian" },
    { code: "BW", label: "Botswana", phone: "267", language: "English" },
    { code: "BY", label: "Belarus", phone: "375", language: "Belarusian" },
    { code: "BZ", label: "Belize", phone: "501", language: "English" },
    {
        code: "CA",
        label: "Canada",
        phone: "1",
        suggested: true,
        language: "English",
    },
    {
        code: "CC",
        label: "Cocos (Keeling) Islands",
        phone: "61",
        language: "English",
    },
    {
        code: "CD",
        label: "Congo, Democratic Republic of the",
        phone: "243",
        language: "French",
    },
    {
        code: "CF",
        label: "Central African Republic",
        phone: "236",
        language: "French",
    },
    {
        code: "CG",
        label: "Congo, Republic of the",
        phone: "242",
        language: "French",
    },
    { code: "CH", label: "Switzerland", phone: "41", language: "German" },
    { code: "CI", label: "Côte d'Ivoire", phone: "225", language: "French" },
    { code: "CK", label: "Cook Islands", phone: "682", language: "English" },
    { code: "CL", label: "Chile", phone: "56", language: "Spanish" },
    { code: "CM", label: "Cameroon", phone: "237", language: "French" },
    { code: "CN", label: "China", phone: "86", language: "Simplified Chinese" },
    { code: "CO", label: "Colombia", phone: "57", language: "Spanish" },
    { code: "CR", label: "Costa Rica", phone: "506", language: "Spanish" },
    { code: "CU", label: "Cuba", phone: "53", language: "Spanish" },
    { code: "CV", label: "Cape Verde", phone: "238", language: "Portuguese" },
    { code: "CW", label: "Curaçao", phone: "599", language: "Dutch" },
    { code: "CX", label: "Christmas Island", phone: "61", language: "English" },
    { code: "CY", label: "Cyprus", phone: "357", language: "Greek" },
    { code: "CZ", label: "Czech Republic", phone: "420", language: "Czech" },
    {
        code: "DE",
        label: "Germany",
        phone: "49",
        suggested: true,
        language: "German",
    },
    { code: "DJ", label: "Djibouti", phone: "253", language: "French" },
    { code: "DK", label: "Denmark", phone: "45", language: "Danish" },
    { code: "DM", label: "Dominica", phone: "1-767", language: "English" },
    {
        code: "DO",
        label: "Dominican Republic",
        phone: "1-809",
        language: "Spanish",
    },
    { code: "DZ", label: "Algeria", phone: "213", language: "Arabic" },
    { code: "EC", label: "Ecuador", phone: "593", language: "Spanish" },
    { code: "EE", label: "Estonia", phone: "372", language: "Estonian" },
    { code: "EG", label: "Egypt", phone: "20", language: "Arabic" },
    { code: "EH", label: "Western Sahara", phone: "212", language: "Arabic" },
    { code: "ER", label: "Eritrea", phone: "291", language: "Tigrinya" },
    { code: "ES", label: "Spain", phone: "34", language: "Spanish" },
    { code: "ET", label: "Ethiopia", phone: "251", language: "Amharic" },
    { code: "FI", label: "Finland", phone: "358", language: "Finnish" },
    { code: "FJ", label: "Fiji", phone: "679", language: "English" },
    {
        code: "FK",
        label: "Falkland Islands (Malvinas)",
        phone: "500",
        language: "English",
    },
    {
        code: "FM",
        label: "Micronesia, Federated States of",
        phone: "691",
        language: "English",
    },
    { code: "FO", label: "Faroe Islands", phone: "298", language: "Faroese" },
    {
        code: "FR",
        label: "France",
        phone: "33",
        suggested: true,
        language: "French",
    },
    { code: "GA", label: "Gabon", phone: "241", language: "French" },
    { code: "GB", label: "United Kingdom", phone: "44", language: "English" },
    { code: "GD", label: "Grenada", phone: "1-473", language: "English" },
    { code: "GE", label: "Georgia", phone: "995", language: "Georgian" },
    { code: "GF", label: "French Guiana", phone: "594", language: "French" },
    { code: "GG", label: "Guernsey", phone: "44", language: "English" },
    { code: "GH", label: "Ghana", phone: "233", language: "English" },
    { code: "GI", label: "Gibraltar", phone: "350", language: "English" },
    { code: "GL", label: "Greenland", phone: "299", language: "Greenlandic" },
    { code: "GM", label: "Gambia", phone: "220", language: "English" },
    { code: "GN", label: "Guinea", phone: "224", language: "French" },
    { code: "GP", label: "Guadeloupe", phone: "590", language: "French" },
    {
        code: "GQ",
        label: "Equatorial Guinea",
        phone: "240",
        language: "Spanish",
    },
    { code: "GR", label: "Greece", phone: "30", language: "Greek" },
    {
        code: "GS",
        label: "South Georgia and the South Sandwich Islands",
        phone: "500",
        language: "English",
    },
    { code: "GT", label: "Guatemala", phone: "502", language: "Spanish" },
    { code: "GU", label: "Guam", phone: "1-671", language: "English" },
    {
        code: "GW",
        label: "Guinea-Bissau",
        phone: "245",
        language: "Portuguese",
    },
    { code: "GY", label: "Guyana", phone: "592", language: "English" },
    {
        code: "HK",
        label: "Hong Kong",
        phone: "852",
        language: "Traditional Chinese",
    },
    {
        code: "HM",
        label: "Heard Island and McDonald Islands",
        phone: "672",
        language: "English",
    },
    { code: "HN", label: "Honduras", phone: "504", language: "Spanish" },
    { code: "HR", label: "Croatia", phone: "385", language: "Croatian" },
    { code: "HT", label: "Haiti", phone: "509", language: "Haitian Creole" },
    { code: "HU", label: "Hungary", phone: "36", language: "Hungarian" },
    { code: "ID", label: "Indonesia", phone: "62", language: "Indonesian" },
    { code: "IE", label: "Ireland", phone: "353", language: "English" },
    { code: "IL", label: "Israel", phone: "972", language: "Hebrew" },
    { code: "IM", label: "Isle of Man", phone: "44", language: "English" },
    { code: "IN", label: "India", phone: "91", language: "Hindi" },
    {
        code: "IO",
        label: "British Indian Ocean Territory",
        phone: "246",
        language: "English",
    },
    { code: "IQ", label: "Iraq", phone: "964", language: "Arabic" },
    {
        code: "IR",
        label: "Iran, Islamic Republic of",
        phone: "98",
        language: "Persian",
    },
    { code: "IS", label: "Iceland", phone: "354", language: "Icelandic" },
    { code: "IT", label: "Italy", phone: "39", language: "Italian" },
    { code: "JE", label: "Jersey", phone: "44", language: "English" },
    { code: "JM", label: "Jamaica", phone: "1-876", language: "English" },
    { code: "JO", label: "Jordan", phone: "962", language: "Arabic" },
    {
        code: "JP",
        label: "Japan",
        phone: "81",
        suggested: true,
        language: "Japanese",
    },
    { code: "KE", label: "Kenya", phone: "254", language: "English" },
    { code: "KG", label: "Kyrgyzstan", phone: "996", language: "Kyrgyz" },
    { code: "KH", label: "Cambodia", phone: "855", language: "Khmer" },
    { code: "KI", label: "Kiribati", phone: "686", language: "English" },
    { code: "KM", label: "Comoros", phone: "269", language: "Comorian" },
    {
        code: "KN",
        label: "Saint Kitts and Nevis",
        phone: "1-869",
        language: "English",
    },
    {
        code: "KP",
        label: "Korea, Democratic People's Republic of",
        phone: "850",
        language: "Korean",
    },
    {
        code: "KR",
        label: "Korea, Republic of",
        phone: "82",
        language: "Korean",
    },
    { code: "KW", label: "Kuwait", phone: "965", language: "Arabic" },
    {
        code: "KY",
        label: "Cayman Islands",
        phone: "1-345",
        language: "English",
    },
    { code: "KZ", label: "Kazakhstan", phone: "7", language: "Kazakh" },
    {
        code: "LA",
        label: "Lao People's Democratic Republic",
        phone: "856",
        language: "Lao",
    },
    { code: "LB", label: "Lebanon", phone: "961", language: "Arabic" },
    { code: "LC", label: "Saint Lucia", phone: "1-758", language: "English" },
    { code: "LI", label: "Liechtenstein", phone: "423", language: "German" },
    { code: "LK", label: "Sri Lanka", phone: "94", language: "Sinhala" },
    { code: "LR", label: "Liberia", phone: "231", language: "English" },
    { code: "LS", label: "Lesotho", phone: "266", language: "Sesotho" },
    { code: "LT", label: "Lithuania", phone: "370", language: "Lithuanian" },
    {
        code: "LU",
        label: "Luxembourg",
        phone: "352",
        language: "Luxembourgish",
    },
    { code: "LV", label: "Latvia", phone: "371", language: "Latvian" },
    { code: "LY", label: "Libya", phone: "218", language: "Arabic" },
    { code: "MA", label: "Morocco", phone: "212", language: "Arabic" },
    { code: "MC", label: "Monaco", phone: "377", language: "French" },
    {
        code: "MD",
        label: "Moldova, Republic of",
        phone: "373",
        language: "Romanian",
    },
    { code: "ME", label: "Montenegro", phone: "382", language: "Montenegrin" },
    {
        code: "MF",
        label: "Saint Martin (French part)",
        phone: "590",
        language: "French",
    },
    { code: "MG", label: "Madagascar", phone: "261", language: "Malagasy" },
    {
        code: "MH",
        label: "Marshall Islands",
        phone: "692",
        language: "English",
    },
    {
        code: "MK",
        label: "Macedonia, the Former Yugoslav Republic of",
        phone: "389",
        language: "Macedonian",
    },
    { code: "ML", label: "Mali", phone: "223", language: "French" },
    { code: "MM", label: "Myanmar", phone: "95", language: "Burmese" },
    { code: "MN", label: "Mongolia", phone: "976", language: "Mongolian" },
    {
        code: "MO",
        label: "Macao",
        phone: "853",
        language: "Traditional Chinese",
    },
    {
        code: "MP",
        label: "Northern Mariana Islands",
        phone: "1-670",
        language: "English",
    },
    { code: "MQ", label: "Martinique", phone: "596", language: "French" },
    { code: "MR", label: "Mauritania", phone: "222", language: "Arabic" },
    { code: "MS", label: "Montserrat", phone: "1-664", language: "English" },
    { code: "MT", label: "Malta", phone: "356", language: "Maltese" },
    { code: "MU", label: "Mauritius", phone: "230", language: "English" },
    { code: "MV", label: "Maldives", phone: "960", language: "Dhivehi" },
    { code: "MW", label: "Malawi", phone: "265", language: "English" },
    { code: "MX", label: "Mexico", phone: "52", language: "Spanish" },
    { code: "MY", label: "Malaysia", phone: "60", language: "Malay" },
    { code: "MZ", label: "Mozambique", phone: "258", language: "Portuguese" },
    { code: "NA", label: "Namibia", phone: "264", language: "English" },
    { code: "NC", label: "New Caledonia", phone: "687", language: "French" },
    { code: "NE", label: "Niger", phone: "227", language: "French" },
    { code: "NF", label: "Norfolk Island", phone: "672", language: "English" },
    { code: "NG", label: "Nigeria", phone: "234", language: "English" },
    { code: "NI", label: "Nicaragua", phone: "505", language: "Spanish" },
    { code: "NL", label: "Netherlands", phone: "31", language: "Dutch" },
    { code: "NO", label: "Norway", phone: "47", language: "Norwegian" },
    { code: "NP", label: "Nepal", phone: "977", language: "Nepali" },
    { code: "NR", label: "Nauru", phone: "674", language: "English" },
    { code: "NU", label: "Niue", phone: "683", language: "English" },
    { code: "NZ", label: "New Zealand", phone: "64", language: "English" },
    { code: "OM", label: "Oman", phone: "968", language: "Arabic" },
    { code: "PA", label: "Panama", phone: "507", language: "Spanish" },
    { code: "PE", label: "Peru", phone: "51", language: "Spanish" },
    { code: "PF", label: "French Polynesia", phone: "689", language: "French" },
    {
        code: "PG",
        label: "Papua New Guinea",
        phone: "675",
        language: "English",
    },
    { code: "PH", label: "Philippines", phone: "63", language: "Filipino" },
    { code: "PK", label: "Pakistan", phone: "92", language: "Urdu" },
    { code: "PL", label: "Poland", phone: "48", language: "Polish" },
    {
        code: "PM",
        label: "Saint Pierre and Miquelon",
        phone: "508",
        language: "French",
    },
    { code: "PN", label: "Pitcairn", phone: "870", language: "English" },
    { code: "PR", label: "Puerto Rico", phone: "1", language: "Spanish" },
    {
        code: "PS",
        label: "Palestine, State of",
        phone: "970",
        language: "Arabic",
    },
    { code: "PT", label: "Portugal", phone: "351", language: "Portuguese" },
    { code: "PW", label: "Palau", phone: "680", language: "English" },
    { code: "PY", label: "Paraguay", phone: "595", language: "Spanish" },
    { code: "QA", label: "Qatar", phone: "974", language: "Arabic" },
    { code: "RE", label: "Réunion", phone: "262", language: "French" },
    { code: "RO", label: "Romania", phone: "40", language: "Romanian" },
    { code: "RS", label: "Serbia", phone: "381", language: "Serbian" },
    {
        code: "RU",
        label: "Russian Federation",
        phone: "7",
        language: "Russian",
    },
    { code: "RW", label: "Rwanda", phone: "250", language: "Kinyarwanda" },
    { code: "SA", label: "Saudi Arabia", phone: "966", language: "Arabic" },
    { code: "SB", label: "Solomon Islands", phone: "677", language: "English" },
    { code: "SC", label: "Seychelles", phone: "248", language: "English" },
    { code: "SD", label: "Sudan", phone: "249", language: "Arabic" },
    { code: "SE", label: "Sweden", phone: "46", language: "Swedish" },
    { code: "SG", label: "Singapore", phone: "65", language: "English" },
    { code: "SH", label: "Saint Helena", phone: "290", language: "English" },
    { code: "SI", label: "Slovenia", phone: "386", language: "Slovene" },
    {
        code: "SJ",
        label: "Svalbard and Jan Mayen",
        phone: "47",
        language: "Norwegian",
    },
    { code: "SK", label: "Slovakia", phone: "421", language: "Slovak" },
    { code: "SL", label: "Sierra Leone", phone: "232", language: "English" },
    { code: "SM", label: "San Marino", phone: "378", language: "Italian" },
    { code: "SN", label: "Senegal", phone: "221", language: "French" },
    { code: "SO", label: "Somalia", phone: "252", language: "Somali" },
    { code: "SR", label: "Suriname", phone: "597", language: "Dutch" },
    { code: "SS", label: "South Sudan", phone: "211", language: "English" },
    {
        code: "ST",
        label: "Sao Tome and Principe",
        phone: "239",
        language: "Portuguese",
    },
    { code: "SV", label: "El Salvador", phone: "503", language: "Spanish" },
    {
        code: "SX",
        label: "Sint Maarten (Dutch part)",
        phone: "1-721",
        language: "Dutch",
    },
    {
        code: "SY",
        label: "Syrian Arab Republic",
        phone: "963",
        language: "Arabic",
    },
    { code: "SZ", label: "Swaziland", phone: "268", language: "English" },
    {
        code: "TC",
        label: "Turks and Caicos Islands",
        phone: "1-649",
        language: "English",
    },
    { code: "TD", label: "Chad", phone: "235", language: "French" },
    {
        code: "TF",
        label: "French Southern Territories",
        phone: "262",
        language: "French",
    },
    { code: "TG", label: "Togo", phone: "228", language: "French" },
    { code: "TH", label: "Thailand", phone: "66", language: "Thai" },
    { code: "TJ", label: "Tajikistan", phone: "992", language: "Tajik" },
    { code: "TK", label: "Tokelau", phone: "690", language: "English" },
    { code: "TL", label: "Timor-Leste", phone: "670", language: "Portuguese" },
    { code: "TM", label: "Turkmenistan", phone: "993", language: "Turkmen" },
    { code: "TN", label: "Tunisia", phone: "216", language: "Arabic" },
    { code: "TO", label: "Tonga", phone: "676", language: "English" },
    { code: "TR", label: "Turkey", phone: "90", language: "Turkish" },
    {
        code: "TT",
        label: "Trinidad and Tobago",
        phone: "1-868",
        language: "English",
    },
    { code: "TV", label: "Tuvalu", phone: "688", language: "English" },
    {
        code: "TW",
        label: "Taiwan, Province of China",
        phone: "886",
        language: "Traditional Chinese",
    },
    {
        code: "TZ",
        label: "United Republic of Tanzania",
        phone: "255",
        language: "Swahili",
    },
    { code: "UA", label: "Ukraine", phone: "380", language: "Ukrainian" },
    { code: "UG", label: "Uganda", phone: "256", language: "English" },
    {
        code: "US",
        label: "United States",
        phone: "1",
        suggested: true,
        language: "English",
    },
    { code: "UY", label: "Uruguay", phone: "598", language: "Spanish" },
    { code: "UZ", label: "Uzbekistan", phone: "998", language: "Uzbek" },
    {
        code: "VA",
        label: "Holy See (Vatican City State)",
        phone: "379",
        language: "Italian",
    },
    {
        code: "VC",
        label: "Saint Vincent and the Grenadines",
        phone: "1-784",
        language: "English",
    },
    { code: "VE", label: "Venezuela", phone: "58", language: "Spanish" },
    {
        code: "VG",
        label: "British Virgin Islands",
        phone: "1-284",
        language: "English",
    },
    {
        code: "VI",
        label: "US Virgin Islands",
        phone: "1-340",
        language: "English",
    },
    { code: "VN", label: "Vietnam", phone: "84", language: "Vietnamese" },
    { code: "VU", label: "Vanuatu", phone: "678", language: "Bislama" },
    {
        code: "WF",
        label: "Wallis and Futuna",
        phone: "681",
        language: "French",
    },
    { code: "WS", label: "Samoa", phone: "685", language: "Samoan" },
    { code: "XK", label: "Kosovo", phone: "383", language: "Albanian" },
    { code: "YE", label: "Yemen", phone: "967", language: "Arabic" },
    { code: "YT", label: "Mayotte", phone: "262", language: "French" },
    { code: "ZA", label: "South Africa", phone: "27", language: "Afrikaans" },
    { code: "ZM", label: "Zambia", phone: "260", language: "English" },
    { code: "ZW", label: "Zimbabwe", phone: "263", language: "English" },
];
