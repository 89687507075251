import { format, formatISO } from "date-fns";
import { getAirpotsList } from "./getAirportList";
import { getNearestAirports } from "./getNearestAirports";
import { matchClassValues } from "./matchCabinClassValue";
import { removeDiacritics } from "./removeDiacritics";
import { removeAccents } from "./removeAccents";

export const processFlightsOptions = async (flightsOptions, projectData) => {
    const citiesDataGeo = projectData?.citiesData;

    let searchSeparatedFlights = false;

    const initDate = format(flightsOptions?.departureDate, "yyyy-MM-dd");

    let slices = [];
    let passengers = [];
    const cities = Promise.all(
        projectData.cities.map(async (city) => {
            let response = await getAirpotsList(city);

            if (
                response.length === 0 ||
                (response?.[0]?.name &&
                    removeDiacritics(
                        removeAccents(response[0].name.toLowerCase()),
                    )?.includes(
                        removeDiacritics(removeAccents(city?.toLowerCase())),
                    ) === false)
            ) {
                const cityData = citiesDataGeo.find(
                    (cityData) =>
                        removeDiacritics(
                            removeAccents(cityData?.name?.toLowerCase()),
                        ) ===
                        removeDiacritics(removeAccents(city?.toLowerCase())),
                );
                const body = {
                    radius: 30,
                    geoNameId: cityData?.geonameid,
                    queryString: city,
                    lat: cityData?.latitude,
                    lng: cityData?.longitude,
                };
                response = await getNearestAirports(body, 3, 500);
            }

            return {
                name: city,
                code: response?.[0]?.code || response?.airports?.[0]?.iata_code,
            };
        }),
    );
    const citiesData = await cities;

    slices.push({
        origin: flightsOptions?.departureCity?.code,
        destination:
            flightsOptions?.arrivalAirport?.code || citiesData?.[0]?.code,
        departure_date: initDate,
        morningFilter: true,
        itineraryCity:
            citiesData?.[0]?.name?.toLowerCase() ||
            flightsOptions?.arrivalAirport?.name ||
            "",
        type: "arrival",
    });
    for (let i = 0; i < citiesData.length - 1; i++) {
        slices.push({
            origin: citiesData?.[i]?.code,
            destination: citiesData?.[i + 1]?.code,
            departure_date: projectData?.endDate?.[i],
            itineraryCity: citiesData?.[i]?.name?.toLowerCase(),
            type: "departure",
        });
    }
    //para la vuelta si se ha seleccionado un aeropuerto de llegada/destino en el formulario de itinerario

    slices.push({
        origin:
            flightsOptions?.arrivalAirport?.code ||
            citiesData?.[citiesData.length - 1]?.code,
        destination: flightsOptions?.departureCity?.code,
        departure_date: projectData?.endDate?.[citiesData.length - 1],
        itineraryCity: citiesData?.[citiesData.length - 1]?.name?.toLowerCase(),
        type: "departure",
    });

    if (projectData?.isABigProject) {
        passengers.push(...[{ type: "adult" }]);
    } else {
        for (let i = 0; i < flightsOptions.passangers.adults; i++) {
            passengers.push({
                type: "adult",
            });
        }
        for (let i = 0; i < flightsOptions.passangers.child; i++) {
            passengers.push({
                type: "child",
            });
        }
        for (let i = 0; i < flightsOptions.passangers.infants; i++) {
            passengers.push({
                type: "infant_without_seat",
            });
        }
    }

    if (
        flightsOptions.flightTimeRanges.departure?.some((time) => time) ||
        flightsOptions.flightTimeRanges.arrival?.some((time) => time)
    ) {
        searchSeparatedFlights = true;
    }

    const objectQuery = {
        slices,
        passengers,
        cabin_class: matchClassValues(flightsOptions.class),
        currency: "EUR",
        searchSeparatedFlights,
        flightTimeRanges: {
            departure: flightsOptions.flightTimeRanges.departure?.map((time) =>
                time ? formatISO(time) : null,
            ),
            arrival: flightsOptions.flightTimeRanges.arrival?.map((time) =>
                time ? formatISO(time) : null,
            ),
        },
    };

    if (flightsOptions.directFlight) {
        objectQuery.max_connections = 0;
    }

    localStorage.setItem("flightsOptions", JSON.stringify(flightsOptions));

    return objectQuery;
};
