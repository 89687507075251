const ClientViewOptions = [
    {
        label: "Show Total Prices",
        value: "showTotalPrice",
    },
    {
        label: "Show Prices Per Person",
        value: "showPricePerPerson",
    },
    {
        label: "Dont Show Prices",
        value: "DontShowPrices",
    },
    {
        label: "Show Daily Weather",
        value: "showWeather",
    },
    {
        label: "Show Daily Images",
        value: "showDailyPicture",
    },
    {
        label: "Show Hotel Details Widget",
        value: "showHotelsWidget",
    },
];

export { ClientViewOptions };
