import { Link, useLocation } from "react-router-dom";
import { paths } from "src/routes/paths";
import Logo from "src/components/logo-saas";
import Button from "src/components/ui/button";
import { useLocales } from "src/locales";

const HEADER_LINKS = ["Contact Us", "About Us"];

const BIZ_LOGIN = "https://travelandz.business/business-travel/auth/login";
const BIZ_SIGN_UP = "https://travelandz.business/business-travel/auth/register";
const HOME_2_PATH = "/home2";

const Header = () => {
    const { pathname } = useLocation();
    const { t } = useLocales();

    return (
        <header className="fixed top-0 left-0 w-full bg-gray-50 border-b border-gray-300 shadow-sm px-4 md:px-8 z-[999]">
            <div className="container mx-auto flex items-center justify-between py-2 ">
                {/* LOGO */}
                <Logo />

                {/* NAVIGATION LINKS (Hidden on small screens) */}
                <nav className="hidden md:flex space-x-6">
                    {HEADER_LINKS.map((item) => (
                        <Link
                            key={item}
                            to={`/${item.toLowerCase().replace(" ", "-")}`}
                            className="text-lg font-medium text-gray-900 hover:text-blue-600 transition"
                        >
                            {t(item)}
                        </Link>
                    ))}
                    <Link
                        to="/home2"
                        className="text-lg font-medium text-gray-900 hover:text-blue-600 transition"
                    >
                        {t("Travel Agencies")}
                    </Link>
                </nav>

                {/* BUTTONS here */}
                <div className="flex space-x-4">
                    <Button
                        variant="outline"
                        href={
                            pathname === HOME_2_PATH
                                ? paths.authDemo.modern.login
                                : BIZ_SIGN_UP
                        }
                    >
                        {t("Login")}
                    </Button>

                    <Button
                        variant="default"
                        className="hidden md:block" // This hides the button on mobile and shows it on md and larger screens
                        href={
                            pathname === HOME_2_PATH
                                ? paths.authDemo.modern.register
                                : BIZ_LOGIN
                        }
                    >
                        {t("Sign Up")}
                    </Button>
                </div>
            </div>
        </header>
    );
};

export default Header;

// import { HeaderShadow, LoginButton } from "../_common";

// import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Link from "@mui/material/Link";
// import Container from "@mui/material/Container";
// import { HEADER } from "../config-layout";
// import Logo from "src/components/logo-saas";
// import Stack from "@mui/material/Stack";
// import Toolbar from "@mui/material/Toolbar";
// import { bgBlur } from "src/theme/css";
// import { useOffSetTop } from "src/hooks/use-off-set-top";
// import { useResponsive } from "src/hooks/use-responsive";
// import { useTheme } from "@mui/material/styles";
// import { paths } from "src/routes/paths";
// import { useLocales } from "src/locales";

// export default function Header() {
//     const theme = useTheme();
//     const { t } = useLocales();
//     /* const mdUp = useResponsive("up", "md"); */

//     const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

//     return (
//         <AppBar>
//             <Toolbar
//                 disableGutters
//                 sx={{
//                     height: {
//                         xs: HEADER.H_MOBILE,
//                         md: HEADER.H_DESKTOP,
//                     },
//                     transition: theme.transitions.create(["height"], {
//                         easing: theme.transitions.easing.easeInOut,
//                         duration: theme.transitions.duration.shorter,
//                     }),
//                     ...(offsetTop && {
//                         ...bgBlur({
//                             color: theme.palette.background.default,
//                         }),
//                         height: {
//                             md: HEADER.H_DESKTOP_OFFSET,
//                         },
//                     }),
//                 }}
//             >
//                 <Container
//                     sx={{
//                         height: 1,
//                         display: "flex",
//                         alignItems: "center",
//                         paddingBottom: 4,
//                         paddingTop: 2,
//                         marginTop: { xs: 0, md: 2 },
//                         marginBottom: { xs: 0, md: 2 },
//                     }}
//                 >
//                     <Logo />

//                     <Box sx={{ flexGrow: 1 }} />

//                     <Stack
//                         alignItems="center"
//                         direction={{ xs: "row", md: "row-reverse" }}
//                     >
//                         <LoginButton />
//                     </Stack>
//                 </Container>
//             </Toolbar>

//             {offsetTop && <HeaderShadow />}
//         </AppBar>
//     );
// }
