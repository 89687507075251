import clsx from "clsx";

const buttonStyles = {
    base: "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-full font-bold transition-all disabled:pointer-events-none disabled:opacity-50 outline-none focus-visible:ring-2 focus-visible:ring-blue-500 z-[1]",
    variants: {
        default: "bg-[#002DF5] text-[#E5E5E9] shadow-xs hover:bg-[#001BB5]",
        destructive: "bg-red-600 text-white hover:bg-red-700",
        "outline-primary":
            "border-2 border-[#002DF5] border-solid  bg-transparent text-[#002DF5] shadow-xs hover:bg-[#002DF5]/15",
        "outline-secondary":
            "border-2 border-gray-700 border-solid  bg-transparent text-gray-700 shadow-xs hover:bg-gray-300",
        outline:
            "border-2 border-[#10101A] bg-transparent text-[#1A1A1A] hover:bg-[#1A1A1A] hover:text-white outline-offset-[-2px]",
        secondary: "bg-gray-800 text-white hover:bg-gray-700",
        success: "bg-green-600 text-white hover:bg-green-700",
        ghost: "hover:bg-gray-200 text-gray-700",
        link: "text-blue-600 underline hover:text-blue-800",
    },
    sizes: {
        default: "px-6 py-2 text-lg",
        sm: "px-3 py-2 text-sm",
        md: "px-3 py-2 text-base",
        lg: "px-6 py-3 text-xl",
        icon: "p-2",
    },
};

const Button = ({
    className = "",
    variant = "default",
    size = "default",
    asChild = false,
    href, // Accept href as a prop
    children,
    disabled,
    target,
    Icon,
    ...props
}) => {
    const Comp = href ? "a" : "button"; // Use <a> if href exists, else <button>

    return (
        <Comp
            className={clsx(
                buttonStyles.base,
                buttonStyles.variants[variant],
                buttonStyles.sizes[size],
                "font-[Reddit_Sans]",
                className,
                disabled && "pointer-events-none",
                "mt-2 mx-1",
            )}
            {...(href
                ? {
                      href,
                      target: target || "_self",
                      rel: "noopener noreferrer",
                  }
                : {})} // Ensure proper behavior for <a>
            disabled={disabled}
            {...props}
        >
            {children}
        </Comp>
    );
};

export default Button;
